import { useEffect, useState } from 'react';

import useWindowSize from '../../../providers/WindowProvider';
import CommonConstants from '../../../constants/CommonConstants';
import TitleWithLine from '../title_with_line/TitleWithLine';
import ProductCard from '../product_card/ProductCard';

import './ProductGroup.css';

function ProductGroup(props) {
    const windowSize = useWindowSize();
    const calculateColumn = () => {
        let columnCount;
        if (windowSize.width >= CommonConstants.DESKTOP_MINIMUM_WIDTH) {
            columnCount = props.columnCount ? props.columnCount : 3;
        } else {
            columnCount = Math.ceil(
                windowSize.width / (props.columnWidth ? props.columnWidth : 200)
            );
        }
        return [
            columnCount,
            `calc(${100 / columnCount}% - ${
                ((columnCount - 1) * 10) / columnCount
            }px)`,
        ];
    };
    const [calcColumnCount, calcColumnWidth] = calculateColumn();
    const [columnCount, setColumnCount] = useState(calcColumnCount);
    const [columnWidth, setColumnWidth] = useState(calcColumnWidth);
    useEffect(() => {
        setColumnCount(calcColumnCount);
        setColumnWidth(calcColumnWidth);
    }, [calcColumnCount, calcColumnWidth]);

    const renderTitle = () => {
        if (props.titleType === 'h1') {
            return <h1>{props.title}</h1>;
        } else if (props.titleType === 'h2') {
            return <h2>{props.title}</h2>;
        } else {
            return <TitleWithLine title={props.title} />;
        }
    };

    return (
        <div className="product_group">
            {props.title && renderTitle()}
            <div className="product_group_container">
                {props.loading
                    ? [...Array(columnCount * 2 - 1)].map((_, index) => (
                          <div key={index} style={{ width: columnWidth }}>
                              <ProductCard loading />
                          </div>
                      ))
                    : props.data &&
                      props.data.map((product, index) => (
                          <div key={index} style={{ width: columnWidth }}>
                              <ProductCard
                                  src={product.src}
                                  name={product.name}
                                  description={product.description}
                                  price={product.price}
                                  onClick={product.onClick}
                              />
                          </div>
                      ))}
            </div>
        </div>
    );
}

export default ProductGroup;
