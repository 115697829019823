class CoffeeModels {
    static CreateCoffeeType = class {
        name;

        constructor({ coffeeType }) {
            this.name = coffeeType;
        }
    };

    static CreateCoffee = class {
        product;
        images;
        coffee_type;
        grammage;
        country;
        coffee_grinding_type;
        coffee_process_type;

        constructor({
            productStockCode,
            productName,
            productPrice,
            productWholesalePrice,
            productNotes,
            coffeeType,
            coffeeGrammage,
            coffeeCountry,
            coffeeGrindingType,
            coffeeProcessType,
            productImages = [],
            productStockQuantity = 0,
            productPreviousPrice = null,
            productPreviousWholesalePrice = null,
            isOpportunityProduct = false,
        }) {
            this.product = {
                stock_code: productStockCode,
                name: productName,
                price: productPrice,
                wholesale_price: productWholesalePrice,
                product_stock: {
                    quantity: productStockQuantity,
                },
                previous_price: productPreviousPrice,
                previous_wholesale_price: productPreviousWholesalePrice,
                notes: productNotes,
                opportunity_product: isOpportunityProduct,
            };
            this.images = productImages;
            this.coffee_type = {
                name: coffeeType,
            };
            this.grammage = coffeeGrammage;
            this.country = coffeeCountry;
            this.coffee_grinding_type = {
                name: coffeeGrindingType,
            };
            this.coffee_process_type = {
                name: coffeeProcessType,
            };
        }
    };

    static GetCoffee = class {
        stockCode;

        constructor({ stockCode }) {
            this.stockCode = stockCode;
        }
    };
}

export default CoffeeModels;
