import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import NotificationContext from '../../../providers/NotificationProvider';
import PageContentService from '../../../services/PageContentService';
import {
    ChocolateService,
    CoffeeService,
    CacaoService,
    TeaService,
} from '../../../services/ProductService';
import PageContentUtils from '../../../utils/PageContentUtils';
import ProductConstants from '../../../constants/ProductConstants';
import CommonConstants from '../../../constants/CommonConstants';
import useWindowSize from '../../../providers/WindowProvider';
import ProductUtils from '../../../utils/ProductUtils';
import TitleWithLine from '../../common/title_with_line/TitleWithLine';
import ProductGroup from '../../common/product_group/ProductGroup';
import ImageCard from '../../common/image_card/ImageCard';

function ProductPage() {
    const navigate = useNavigate();
    const params = useParams();
    const { raiseNotification } = useContext(NotificationContext);

    const [products, setProducts] = useState([]);
    const [sidePanelData, setSidePanelData] = useState([]);
    const [loadingContent, setLoadingContent] = useState(true);
    const [loadingProducts, setLoadingProducts] = useState(true);

    const windowSize = useWindowSize();
    const isSideVisible =
        windowSize.width >= CommonConstants.DESKTOP_MINIMUM_WIDTH;
    const [sideVisible, setSideVisible] = useState(isSideVisible);
    useEffect(() => setSideVisible(isSideVisible), [isSideVisible]);

    const getProducts = async () => {
        try {
            setLoadingProducts(true);
            let response;
            if (params.type === ProductConstants.productTypes.COFFEE.value) {
                response = await CoffeeService.getCoffees();
            } else if (
                params.type === ProductConstants.productTypes.CHOCOLATE.value
            ) {
                response = await ChocolateService.getChocolates();
            } else if (
                params.type === ProductConstants.productTypes.CACAO.value
            ) {
                response = await CacaoService.getCacaos();
            } else if (
                params.type === ProductConstants.productTypes.TEA.value
            ) {
                response = await TeaService.getTeas();
            }
            let products = response.data;
            for (const i in products) {
                products[i].images = await ProductUtils.convertProductImages(
                    products[i].images
                );
            }
            setProducts(products);
        } catch (error) {
            setProducts([]);
            raiseNotification(`${error}`);
        } finally {
            setLoadingProducts(false);
        }
    };

    const getPageContent = async () => {
        try {
            setLoadingContent(true);
            const response = await PageContentService.getPageContent(
                params.type
            );
            const sidePanelContent =
                await PageContentUtils.convertSidePanelContentImages(
                    response.data['side_panels']
                );
            setSidePanelData(sidePanelContent);
        } catch (error) {
            setSidePanelData([]);
            raiseNotification(`${error}`);
        } finally {
            setLoadingContent(false);
        }
    };

    useEffect(() => {
        getProducts();
        getPageContent();
        // eslint-disable-next-line
    }, [params.type]);

    return (
        <div className="page">
            <div className="page_section row">
                <div className="page_section_main_column">
                    <TitleWithLine
                        title={
                            Object.values(ProductConstants.productTypes).find(
                                (item) => item.value === params.type
                            ).title
                        }
                    />
                    {loadingProducts ? (
                        <ProductGroup loading />
                    ) : (
                        <ProductGroup
                            data={ProductUtils.createProductGroupData(
                                products,
                                navigate
                            )}
                        />
                    )}
                </div>
                {sideVisible && (
                    <div className="page_section_side_column">
                        {loadingContent ? (
                            <ProductGroup columnCount={2} loading />
                        ) : (
                            sidePanelData &&
                            sidePanelData
                                .sort((a, b) => a['index'] - b['index'])
                                .map((section, index) =>
                                    section.content_type === 'product' ? (
                                        <ProductGroup
                                            key={index}
                                            title={section.title}
                                            columnCount={2}
                                            data={ProductUtils.createProductGroupData(
                                                section.products,
                                                navigate
                                            )}
                                        />
                                    ) : (
                                        <ImageCard
                                            key={index}
                                            title={section.title}
                                            src={section.url}
                                            onClick={() =>
                                                window.location.assign(
                                                    section.link
                                                )
                                            }
                                        />
                                    )
                                )
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ProductPage;
