import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MdOutlineCreditCard, MdOutlineShoppingCart } from 'react-icons/md';

import NotificationContext from '../../../providers/NotificationProvider';
import PageContentService from '../../../services/PageContentService';
import {
    CacaoService,
    ChocolateService,
    CoffeeService,
    TeaService,
} from '../../../services/ProductService';
import ProductConstants from '../../../constants/ProductConstants';
import PageContentUtils from '../../../utils/PageContentUtils';
import CommonConstants from '../../../constants/CommonConstants';
import useWindowSize from '../../../providers/WindowProvider';
import ProductUtils from '../../../utils/ProductUtils';
import FileUtils from '../../../utils/FileUtils';
import TitleWithLine from '../../common/title_with_line/TitleWithLine';
import ProductGroup from '../../common/product_group/ProductGroup';
import ProductPrice from './product_price/ProductPrice';
import ProductName from './product_name/ProductName';
import LabelGroup from './label_group/LabelGroup';
import ImageCard from '../../common/image_card/ImageCard';
import Carousel from '../../common/carousel/Carousel';

function ProductDetailPage() {
    const navigate = useNavigate();
    const params = useParams();
    const { raiseNotification } = useContext(NotificationContext);

    const [productDetail, setProductDetail] = useState(undefined);
    const [sidePanelData, setSidePanelData] = useState([]);
    const [loadingContent, setLoadingContent] = useState(true);
    const [loadingProductDetail, setLoadingProductDetail] = useState(true);

    const windowSize = useWindowSize();
    const isSideVisible =
        windowSize.width >= CommonConstants.DESKTOP_MINIMUM_WIDTH;
    const [sideVisible, setSideVisible] = useState(isSideVisible);
    useEffect(() => setSideVisible(isSideVisible), [isSideVisible]);
    const isButtonsHorizontal =
        windowSize.width >= CommonConstants.MOBILE_MINIMUM_WIDTH;
    const [buttonsHorizontal, setButtonsHorizontal] =
        useState(isButtonsHorizontal);
    useEffect(
        () => setButtonsHorizontal(isButtonsHorizontal),
        [isButtonsHorizontal]
    );

    const getProductDetail = async () => {
        try {
            setLoadingProductDetail(true);
            let response;
            if (params.type === ProductConstants.productTypes.COFFEE.value) {
                response = await CoffeeService.getCoffee({
                    stockCode: params.id,
                });
            } else if (
                params.type === ProductConstants.productTypes.CHOCOLATE.value
            ) {
                response = await ChocolateService.getChocolate({
                    stockCode: params.id,
                });
            } else if (
                params.type === ProductConstants.productTypes.CACAO.value
            ) {
                response = await CacaoService.getCacao({
                    stockCode: params.id,
                });
            } else if (
                params.type === ProductConstants.productTypes.TEA.value
            ) {
                response = await TeaService.getTea({
                    stockCode: params.id,
                });
            }
            let product = response.data[0];
            for (const i in product.images) {
                product.images[i].url = await FileUtils.convertToObjectUrl(
                    product.images[i].url
                );
            }
            setProductDetail(product);
        } catch (error) {
            raiseNotification(`${error}`);
        } finally {
            setLoadingProductDetail(false);
        }
    };

    const getPageContent = async () => {
        try {
            setLoadingContent(true);
            const response = await PageContentService.getPageContent(
                params.type
            );
            const sidePanelContent =
                await PageContentUtils.convertSidePanelContentImages(
                    response.data['side_panels']
                );
            setSidePanelData(sidePanelContent);
        } catch (error) {
            setSidePanelData([]);
            raiseNotification(`${error}`);
        } finally {
            setLoadingContent(false);
        }
    };

    useEffect(() => {
        getProductDetail();
        getPageContent();
        // eslint-disable-next-line
    }, [params.id, params.type]);

    return (
        <div className="page">
            <div className="page_section row">
                <div className="page_section_main_column">
                    <TitleWithLine
                        title={`${
                            Object.values(ProductConstants.productTypes).find(
                                (item) => item.value === params.type
                            ).title
                        } > ${params.id}`}
                    />
                    {loadingProductDetail ? (
                        <>
                            <div className="page_section_group">
                                <LabelGroup loading={loadingProductDetail} />
                                <Carousel
                                    aspectRatio={3 / 2}
                                    loading={loadingProductDetail}
                                />
                            </div>
                            <ProductName loading={loadingProductDetail} />
                        </>
                    ) : (
                        <>
                            <div className="page_section_group">
                                <LabelGroup
                                    labels={
                                        productDetail && productDetail.labels
                                    }
                                />
                                <Carousel
                                    aspectRatio={3 / 2}
                                    data={
                                        productDetail &&
                                        productDetail.images
                                            .sort(
                                                (a, b) =>
                                                    a['index'] - b['index']
                                            )
                                            .map((item) => ({
                                                src: item.url,
                                                alt: item.alt,
                                            }))
                                    }
                                />
                            </div>
                            <ProductName
                                name={productDetail && productDetail.name}
                            />
                            {productDetail && productDetail.notes && (
                                <div className="page_section_group">
                                    <TitleWithLine title="Notlar" />
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: productDetail.notes,
                                        }}
                                    />
                                </div>
                            )}
                            {productDetail && productDetail.price && (
                                <div className="page_section_group">
                                    <TitleWithLine title="Fiyat" />
                                    <ProductPrice
                                        price={productDetail.price}
                                        oldPrice={productDetail.previous_price}
                                    />
                                </div>
                            )}
                            <div className="page_section_group">
                                <TitleWithLine title="Alım" />
                                <div
                                    className={
                                        buttonsHorizontal
                                            ? 'page_section_group row'
                                            : 'page_section_group'
                                    }
                                >
                                    <button className="button outline primary full_width">
                                        <MdOutlineCreditCard size={20} />
                                        Şimdi Al
                                    </button>
                                    <button className="button solid full_width">
                                        <MdOutlineShoppingCart size={20} />
                                        Sepete Ekle
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {sideVisible && (
                    <div className="page_section_side_column">
                        {loadingContent ? (
                            <ProductGroup columnCount={2} loading />
                        ) : (
                            sidePanelData &&
                            sidePanelData
                                .sort((a, b) => a['index'] - b['index'])
                                .map((section, index) =>
                                    section.content_type === 'product' ? (
                                        <ProductGroup
                                            key={index}
                                            title={section.title}
                                            columnCount={2}
                                            data={ProductUtils.createProductGroupData(
                                                section.products,
                                                navigate
                                            )}
                                        />
                                    ) : (
                                        <ImageCard
                                            key={index}
                                            title={section.title}
                                            src={section.url}
                                            onClick={() =>
                                                window.location.assign(
                                                    section.link
                                                )
                                            }
                                        />
                                    )
                                )
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ProductDetailPage;
