import { useEffect, useState } from 'react';

import './ProductName.css';

function ProductName(props) {
    const [highlightText, setHighlightText] = useState(undefined);
    const [normalText, setNormalText] = useState(undefined);

    const setNameParts = (name) => {
        const nameParts = name.split(' ');
        setHighlightText(nameParts[0]);
        if (nameParts.length > 1) {
            setNormalText(nameParts.slice(1).join(' '));
        }
    };

    useEffect(() => {
        if (props.name) {
            setNameParts(props.name);
        }
    }, [props.name]);

    return (
        <h2
            className={
                props.loading ? 'product_name loading_shine' : 'product_name'
            }
        >
            <b>{highlightText}</b>
            {normalText && ` ${normalText}`}
        </h2>
    );
}

export default ProductName;
