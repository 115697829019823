import { jwtDecode } from 'jwt-decode';

class AuthUtils {
    static decodeToken(token) {
        try {
            return jwtDecode(token);
        } catch (error) {
            return undefined;
        }
    }

    static isTokenValid(token) {
        const decodedToken = this.decodeToken(token);
        const currentTime = Date.now() / 1000;
        return Boolean(
            decodedToken && decodedToken.exp && decodedToken.exp >= currentTime
        );
    }
}

export default AuthUtils;
