import FileService from '../services/FileService';

class FileUtils {
    static async convertToObjectUrl(url) {
        const imageResponse = await FileService.getImage(url);
        return URL.createObjectURL(imageResponse.data);
    }
}

export default FileUtils;
