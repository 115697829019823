import axios from 'axios';

import LocalStorage from '../constants/LocalStorage';

class AxiosUtils {
    static setDefaultSettings() {
        axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
        axios.defaults.headers.common['Content-Type'] = 'application/json';
    }

    static setAuthHeader() {
        axios.defaults.headers.common['Authorization'] = `Bearer ${
            localStorage[LocalStorage.USER_ACCESS_TOKEN]
        }`;
    }
}

export default AxiosUtils;
