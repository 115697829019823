class AuthModels {
    static GetAccessToken = class {
        email;
        password;

        constructor({ email, password }) {
            this.email = email;
            this.password = password;
        }
    };
}

export default AuthModels;
