import './TitleWithLine.css';

function TitleWithLine(props) {
    return (
        <div className="title_with_line">
            {props.title && (
                <p className="title_with_line_title">{props.title}</p>
            )}
            <div className="title_with_line_line"></div>
        </div>
    );
}

export default TitleWithLine;
