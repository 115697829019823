import { useState, useEffect } from 'react';

import LocalStorage from '../constants/LocalStorage';
import AuthUtils from '../utils/AuthUtils';

const isAuthenticated = () => {
    return Boolean(
        localStorage[LocalStorage.USER_EMAIL] &&
            localStorage[LocalStorage.USER_ACCESS_TOKEN] &&
            AuthUtils.isTokenValid(localStorage[LocalStorage.USER_ACCESS_TOKEN])
    ).valueOf();
};

function useAuthentication() {
    const [authenticated, setAuthenticated] = useState(isAuthenticated());

    useEffect(() => {
        const handleAuthentication = () => {
            setAuthenticated(isAuthenticated());
        };
        window.addEventListener('authentication', handleAuthentication);

        return () => {
            window.removeEventListener('authentication', handleAuthentication);
        };
    }, []);

    return authenticated;
}

export default useAuthentication;
