import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
    MdOutlineMenu,
    MdOutlineClose,
    MdOutlinePerson,
    MdOutlineShoppingCart,
    MdOutlinePayments,
    MdOutlineNotifications,
    MdOutlineLogin,
    MdOutlineLogout,
} from 'react-icons/md';

import { ReactComponent as Logo } from '../../../assets/logo.svg';
import NotificationContext from '../../../providers/NotificationProvider';
import CommonConstants from '../../../constants/CommonConstants';
import LocalStorage from '../../../constants/LocalStorage';
import useAuthentication from '../../../providers/AuthProvider';
import useWindowSize from '../../../providers/WindowProvider';

import './NavBar.css';

function NavBarLogo() {
    const navigate = useNavigate();

    return <Logo className="navbar_logo" onClick={() => navigate('/')} />;
}

function NavBarButton(props) {
    const className = `navbar_button ${
        props.className ? props.className : ''
    }`.trim();
    return (
        <button
            className={className}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    );
}

function NavBarRouteButtons(props) {
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <>
            <NavBarButton
                className={props.large && 'large'}
                disabled={location.pathname === '/kahve'}
                onClick={() => navigate('/urunler/kahve')}
            >
                KAHVE
            </NavBarButton>
            <NavBarButton
                className={props.large && 'large'}
                disabled={location.pathname === '/cikolata'}
                onClick={() => navigate('/urunler/cikolata')}
            >
                ÇİKOLATA
            </NavBarButton>
            <NavBarButton
                className={props.large && 'large'}
                disabled={location.pathname === '/kakao'}
                onClick={() => navigate('/urunler/kakao')}
            >
                KAKAO
            </NavBarButton>
            <NavBarButton
                className={props.large && 'large'}
                disabled={location.pathname === '/cay'}
                onClick={() => navigate('/urunler/cay')}
            >
                ÇAY
            </NavBarButton>
        </>
    );
}

function AccountButton() {
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <NavBarButton
            className="small_icon_button"
            disabled={location.pathname === '/hesap'}
            onClick={() => navigate('/hesap')}
        >
            <p className="navbar_limited_text">
                {localStorage[LocalStorage.USER_FIRST_NAME] &&
                localStorage[LocalStorage.USER_LAST_NAME]
                    ? `${localStorage[LocalStorage.USER_FIRST_NAME]} ${
                          localStorage[LocalStorage.USER_LAST_NAME]
                      }`
                    : 'Bilinmeyen Kullanıcı'}
            </p>
            <MdOutlinePerson size={20} />
        </NavBarButton>
    );
}

function BalanceButton() {
    const setBalance = (balance) => {
        if (!balance) {
            return 'Bakiye Yok';
        } else if (isNaN(balance)) {
            return 'Hatalı Bakiye';
        } else if (parseFloat(balance) > 999999) {
            return '999999+ TL';
        } else {
            return `${parseFloat(balance).toFixed(2).toString()} TL`;
        }
    };
    return (
        <NavBarButton className="small_icon_button">
            {setBalance(localStorage[LocalStorage.USER_BALANCE])}
            <MdOutlinePayments size={20} />
        </NavBarButton>
    );
}

function CartButton() {
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <NavBarButton
            className="small_icon_button"
            disabled={location.pathname === '/sepet'}
            onClick={() => navigate('/sepet')}
        >
            <MdOutlineShoppingCart size={20} />
        </NavBarButton>
    );
}

function NotificationButton() {
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <NavBarButton
            className="small_icon_button"
            disabled={location.pathname === '/bildirimler'}
            onClick={() => navigate('/bildirimler')}
        >
            <MdOutlineNotifications size={20} />
        </NavBarButton>
    );
}

function SignOutButton(props) {
    const { raiseNotification } = useContext(NotificationContext);

    return (
        <NavBarButton
            className="small_icon_button"
            onClick={() => {
                localStorage.clear();
                window.dispatchEvent(new Event('authentication'));
                raiseNotification('Çıkış yapıldı.');
            }}
        >
            {!props.onlyIcon && 'Çıkış Yap'}
            <MdOutlineLogout size={20} />
        </NavBarButton>
    );
}

function SignInButton(props) {
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <NavBarButton
            className="small_icon_button"
            disabled={location.pathname === '/giris'}
            onClick={() => {
                navigate('/giris');
            }}
        >
            {!props.onlyIcon && 'Giriş Yap'}
            <MdOutlineLogin size={20} />
        </NavBarButton>
    );
}

function TopBar(props) {
    const authenticated = useAuthentication();
    const windowSize = useWindowSize();

    return (
        <div className="topbar">
            <div className="topbar_items left">
                {windowSize.width < CommonConstants.DESKTOP_MINIMUM_WIDTH ? (
                    <>
                        <NavBarButton
                            onClick={() =>
                                props.setSideBarOpen((prevState) => !prevState)
                            }
                        >
                            {props.sideBarOpen ? (
                                <MdOutlineClose size={25} />
                            ) : (
                                <MdOutlineMenu size={25} />
                            )}
                        </NavBarButton>
                        <div className="topbar_logo_layer">
                            <NavBarLogo />
                        </div>
                    </>
                ) : (
                    <>
                        <NavBarLogo />
                        <div className="topbar_items buttons">
                            {windowSize.width >=
                                CommonConstants.DESKTOP_MINIMUM_WIDTH && (
                                <NavBarRouteButtons />
                            )}
                        </div>
                    </>
                )}
            </div>
            <div className="topbar_items right">
                {windowSize.width < CommonConstants.DESKTOP_MINIMUM_WIDTH ? (
                    authenticated ? (
                        <CartButton />
                    ) : (
                        <SignInButton onlyIcon />
                    )
                ) : authenticated ? (
                    <>
                        <AccountButton />
                        <BalanceButton />
                        <NotificationButton />
                        <CartButton />
                        <SignOutButton onlyIcon />
                    </>
                ) : (
                    <SignInButton />
                )}
            </div>
        </div>
    );
}

function SideBar(props) {
    const authenticated = useAuthentication();

    return (
        <div className={props.sideBarOpen ? 'sidebar' : 'sidebar hidden'}>
            <div className="sidebar_menu">
                {authenticated && (
                    <div className="sidebar_items row">
                        <AccountButton />
                        <BalanceButton />
                        <NotificationButton />
                    </div>
                )}
                <div className="sidebar_items buttons">
                    <NavBarRouteButtons large />
                </div>
                {authenticated && (
                    <div className="sidebar_items">
                        <SignOutButton />
                    </div>
                )}
            </div>
            <div
                className="sidebar_bg"
                onClick={() => props.setSideBarOpen(false)}
            ></div>
        </div>
    );
}

function NavBar() {
    const authenticated = useAuthentication();
    const windowSize = useWindowSize();
    const location = useLocation();
    const [sideBarOpen, setSideBarOpen] = useState(false);

    const isSideBarActive =
        windowSize.width < CommonConstants.DESKTOP_MINIMUM_WIDTH;

    useEffect(() => {
        if (!isSideBarActive) {
            setSideBarOpen(false);
        }
    }, [isSideBarActive]);

    useEffect(() => {
        setSideBarOpen(false);
    }, [location, authenticated]);

    return (
        <>
            <TopBar sideBarOpen={sideBarOpen} setSideBarOpen={setSideBarOpen} />
            {windowSize.width < CommonConstants.DESKTOP_MINIMUM_WIDTH && (
                <SideBar
                    sideBarOpen={sideBarOpen}
                    setSideBarOpen={setSideBarOpen}
                />
            )}
        </>
    );
}

export default NavBar;
