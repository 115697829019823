import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import NotificationContext from '../../../providers/NotificationProvider';
import PageContentService from '../../../services/PageContentService';
import PageContentUtils from '../../../utils/PageContentUtils';
import CommonConstants from '../../../constants/CommonConstants';
import useWindowSize from '../../../providers/WindowProvider';
import ProductUtils from '../../../utils/ProductUtils';
import ProductGroup from '../../common/product_group/ProductGroup';
import ImageCard from '../../common/image_card/ImageCard';
import Carousel from '../../common/carousel/Carousel';

function MainPage() {
    const navigate = useNavigate();
    const { raiseNotification } = useContext(NotificationContext);

    const [feedData, setFeedData] = useState([]);
    const [bannerData, setBannerData] = useState([]);
    const [sidePanelData, setSidePanelData] = useState([]);
    const [loadingContent, setLoadingContent] = useState(true);

    const windowSize = useWindowSize();
    const isSideVisible =
        windowSize.width >= CommonConstants.DESKTOP_MINIMUM_WIDTH;
    const [sideVisible, setSideVisible] = useState(isSideVisible);
    useEffect(() => setSideVisible(isSideVisible), [isSideVisible]);

    const getContent = async () => {
        try {
            setLoadingContent(true);
            const response = await PageContentService.getPageContent(
                CommonConstants.HOME_PAGE_NAME
            );
            const feedContent = await PageContentUtils.convertFeedContentImages(
                response.data['feeds']
            );
            const bannerContent =
                await PageContentUtils.convertBannerContentImages(
                    response.data['banners']
                );
            const sidePanelContent =
                await PageContentUtils.convertSidePanelContentImages(
                    response.data['side_panels']
                );
            setFeedData(feedContent);
            setBannerData(bannerContent);
            setSidePanelData(sidePanelContent);
        } catch (error) {
            raiseNotification(`${error}`);
        } finally {
            setLoadingContent(false);
        }
    };

    useEffect(() => {
        getContent();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="page">
            <div className="page_section">
                <Carousel
                    loading={loadingContent}
                    data={
                        bannerData &&
                        bannerData
                            .sort((a, b) => a['index'] - b['index'])
                            .map((item) => ({
                                src: item.url,
                                alt: item.alt,
                                onClick: () =>
                                    window.location.assign(item.link),
                            }))
                    }
                />
            </div>
            <div className="page_section row">
                <div className="page_section_main_column">
                    {loadingContent ? (
                        <ProductGroup loading />
                    ) : (
                        feedData &&
                        feedData
                            .sort((a, b) => a['index'] - b['index'])
                            .map((section, index) => (
                                <ProductGroup
                                    key={index}
                                    title={section.title}
                                    titleType="h1"
                                    data={ProductUtils.createProductGroupData(
                                        section.products,
                                        navigate
                                    )}
                                />
                            ))
                    )}
                </div>
                {sideVisible && (
                    <div className="page_section_side_column">
                        {loadingContent ? (
                            <ProductGroup columnCount={2} loading />
                        ) : (
                            sidePanelData &&
                            sidePanelData
                                .sort((a, b) => a['index'] - b['index'])
                                .map((section, index) =>
                                    section['content_type'] === 'product' ? (
                                        <ProductGroup
                                            key={index}
                                            title={section.title}
                                            columnCount={2}
                                            data={ProductUtils.createProductGroupData(
                                                section.products,
                                                navigate
                                            )}
                                        />
                                    ) : (
                                        <ImageCard
                                            key={index}
                                            title={section.title}
                                            src={section.url}
                                            onClick={() =>
                                                window.location.assign(
                                                    section.link
                                                )
                                            }
                                        />
                                    )
                                )
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default MainPage;
