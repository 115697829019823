import FileUtils from './FileUtils';

class PageContentUtils {
    static async convertBannerContentImages(content) {
        for (const i in content) {
            content[i].url = await FileUtils.convertToObjectUrl(
                content[i]['image_name']
            );
        }
        return content;
    }

    static async convertFeedContentImages(content) {
        for (const i in content) {
            for (const j in content[i].products) {
                for (const k in content[i].products[j].images) {
                    content[i].products[j].images[k].url =
                        await FileUtils.convertToObjectUrl(
                            content[i].products[j].images[k].url
                        );
                }
            }
        }
        return content;
    }

    static async convertSidePanelContentImages(content) {
        for (const i in content) {
            if (content[i]['content_type'] === 'image') {
                content[i].url = await FileUtils.convertToObjectUrl(
                    content[i]['image_name']
                );
            } else {
                for (const j in content[i].products) {
                    for (const k in content[i].products[j].images) {
                        content[i].products[j].images[k].url =
                            await FileUtils.convertToObjectUrl(
                                content[i].products[j].images[k].url
                            );
                    }
                }
            }
        }
        return content;
    }
}

export default PageContentUtils;
