import { ReactComponent as Logo } from '../../../assets/logo.svg';

import './LoadingPage.css';

function LoadingPage() {
    return (
        <div className="page center">
            <div className="loading_page_container">
                <Logo className="loading_page_logo" />
            </div>
        </div>
    );
}

export default LoadingPage;
