import axios from 'axios';

class FileService {
    static async createImages(images) {
        const formData = new FormData();
        for (const image of images) {
            formData.append('images', image);
        }
        const response = await axios.post(
            '/upload_file/create_images',
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        return response;
    }

    static async getImage(imageName) {
        return await axios.get(`/upload_file/get_image/${imageName}`, {
            responseType: 'blob',
        });
    }
}

export default FileService;
