import { createContext, useState } from 'react';
import { v4 } from 'uuid';

import {
    ToastNotificationList,
    ToastNotification,
} from '../components/common/toast_notification/ToastNotification';

const NotificationContext = createContext();

function NotificationProvider(props) {
    const [notifications, setNotifications] = useState([]);

    const removeNotification = (id) => {
        setNotifications((prevNotifications) =>
            prevNotifications.filter((notification) => notification.id !== id)
        );
    };

    const raiseNotification = (message, { permanent = false } = {}) => {
        const notification = { id: v4(), message: message };

        setNotifications((prevNotifications) => [
            notification,
            ...prevNotifications,
        ]);

        if (!permanent) {
            setTimeout(() => {
                removeNotification(notification.id);
            }, 5000);
        }

        return notification.id;
    };

    return (
        <NotificationContext.Provider
            value={{ raiseNotification, removeNotification }}
        >
            {notifications.length > 0 && (
                <ToastNotificationList>
                    {notifications.map((notification) => (
                        <ToastNotification
                            id={notification.id}
                            message={notification.message}
                            onClose={() => removeNotification(notification.id)}
                        />
                    ))}
                </ToastNotificationList>
            )}
            {props.children}
        </NotificationContext.Provider>
    );
}

export { NotificationProvider };
export default NotificationContext;
