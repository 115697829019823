import { useEffect, useState } from 'react';

import './ProductPrice.css';

function ProductPrice(props) {
    const [discount, setDiscount] = useState(undefined);

    const calculateDiscount = (newPrice, oldPrice) => {
        return Math.floor(((oldPrice - newPrice) * 100) / oldPrice);
    };

    useEffect(() => {
        if (props.oldPrice) {
            const newDiscount = calculateDiscount(props.price, props.oldPrice);
            setDiscount(newDiscount > 0 ? newDiscount : undefined);
        } else {
            setDiscount(undefined);
        }
    }, [props.oldPrice, props.price]);

    return (
        <div>
            {discount && (
                <p className="price_text discount">%{discount} İndirim</p>
            )}
            <div className="product_price_row">
                {props.discount && (
                    <p className="price_text old">{props.oldPrice}TL</p>
                )}
                <p className="price_text">{props.price}TL</p>
            </div>
        </div>
    );
}

export default ProductPrice;
