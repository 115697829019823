import axios from 'axios';

import AuthModels from '../models/AuthModels';

class AuthService {
    static async getAccessToken(data) {
        return await axios.post(
            '/auth/signin',
            new AuthModels.GetAccessToken(data)
        );
    }
}

export default AuthService;
