import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import NotificationContext from '../../../providers/NotificationProvider';
import LocalStorage from '../../../constants/LocalStorage';
import AuthService from '../../../services/AuthService';
import UserService from '../../../services/UserService';
import TitleWithLine from '../../common/title_with_line/TitleWithLine';
import AxiosUtils from '../../../utils/AxiosUtils';
import TextInput from '../../common/text_input/TextInput';

import './Login.css';

function LoginPage() {
    const { raiseNotification, removeNotification } =
        useContext(NotificationContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const signIn = async () => {
        const notificationId = raiseNotification(`Giriş yapılıyor...`);
        try {
            const authResponse = await AuthService.getAccessToken({
                email,
                password,
            });
            localStorage[LocalStorage.USER_EMAIL] = email;
            localStorage[LocalStorage.USER_ACCESS_TOKEN] =
                authResponse.data['access_token'];
            AxiosUtils.setAuthHeader();

            const userResponse = await UserService.getCurrentUser();
            localStorage[LocalStorage.USER_FIRST_NAME] =
                userResponse.data['first_name'];
            localStorage[LocalStorage.USER_LAST_NAME] =
                userResponse.data['last_name'];

            removeNotification(notificationId);
            raiseNotification(`Giriş başarılı.`);

            dispatchEvent(new Event('authentication'));
            navigate('/');
        } catch (error) {
            removeNotification(notificationId);
            raiseNotification(`${error}`);
        }
    };

    return (
        <div className="page center">
            <div className="login_form">
                <TitleWithLine title="Giriş Yap" />
                <div className="login_inputs">
                    <TextInput id="email" label="Email" onChange={setEmail} />
                    <TextInput
                        id="password"
                        label="Şifre"
                        onChange={setPassword}
                        password
                    />
                    <button
                        className="button solid full_width"
                        onClick={signIn}
                    >
                        Giriş Yap
                    </button>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
