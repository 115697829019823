import { useEffect, useState } from 'react';

import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';

import './TextInput.css';

function TextInput(props) {
    const [showPassword, setShowPassword] = useState(false);
    const [currentValue, setCurrentValue] = useState('');

    useEffect(() => {
        if (props.value) {
            props.onChange(props.value);
        }
    }, [props]);

    const isInputValid = (type, input) => {
        if (type === 'int') {
            const accepted = /^[0-9-]*$/i;
            return accepted.test(input);
        } else if (type === 'float') {
            const inputPattern = /^[0-9.-]*$/i;
            return inputPattern.test(input);
        } else {
            return true;
        }
    };

    const handleChange = (e) => {
        if (isInputValid(props.type, e.target.value)) {
            setCurrentValue(e.target.value);
            props.onChange && props.onChange(e.target.value);
        } else {
            e.target.value = currentValue;
        }
    };

    return (
        <div className="text_input_container">
            <input
                id={props.id}
                className="text_input"
                type={props.password && !showPassword ? 'password' : undefined}
                placeholder={props.label}
                value={props.value}
                autoFocus={props.autoFocus}
                onChange={handleChange}
            />
            {props.password &&
                (showPassword ? (
                    <MdOutlineVisibility
                        size={20}
                        onClick={() => setShowPassword(false)}
                    />
                ) : (
                    <MdOutlineVisibilityOff
                        size={20}
                        onClick={() => setShowPassword(true)}
                    />
                ))}
        </div>
    );
}

export default TextInput;
