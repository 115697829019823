class TeaModels {
    static GetTea = class {
        stockCode;

        constructor({ stockCode }) {
            this.stockCode = stockCode;
        }
    };
}

export default TeaModels;
