import { useState } from 'react';

import TitleWithLine from '../title_with_line/TitleWithLine';

import './ImageCard.css';

function ImageCard(props) {
    const [loading, setLoading] = useState(true);

    return (
        <div className="image_card" onClick={props.onClick}>
            {props.title && <TitleWithLine title={props.title} />}
            <div className="image_card_image">
                <img
                    className={loading ? 'hidden' : undefined}
                    src={props.src}
                    alt={props.alt ? props.alt : props.src}
                    onLoad={() => setLoading(false)}
                />
            </div>
        </div>
    );
}

export default ImageCard;
