import { useState } from 'react';

import './ProductCard.css';

function ProductCardImage(props) {
    const [loading, setLoading] = useState(true);
    return (
        <div className="product_card_image">
            <img
                className={loading ? 'hidden' : undefined}
                src={props.src}
                alt={props.alt ? props.alt : props.src}
                onLoad={() => setLoading(false)}
            />
        </div>
    );
}

function ProductCardSkeleton() {
    return (
        <div className="product_card">
            <div className="product_card_image loading_shine"></div>
            {<p className="product_card_text name loading_shine"></p>}
            {<p className="product_card_text description loading_shine"></p>}
            {<p className="product_card_text price loading_shine"></p>}
        </div>
    );
}

function ProductCard(props) {
    return props.loading ? (
        <ProductCardSkeleton />
    ) : (
        <div className="product_card" onClick={props.onClick}>
            <ProductCardImage src={props.src} alt={props.alt} />
            {props.name && (
                <p className="product_card_text name">{props.name}</p>
            )}
            {props.description && (
                <p className="product_card_text description">
                    {props.description}
                </p>
            )}
            {props.price && (
                <p className="product_card_text price">{props.price} TL</p>
            )}
        </div>
    );
}

export default ProductCard;
