import { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import useAuthentication from './providers/AuthProvider';
import CommonUtils from './utils/CommonUtils';
import AxiosUtils from './utils/AxiosUtils';
import NavBar from './components/common/navbar/NavBar';
import MainPage from './components/pages/main/MainPage';
import LoginPage from './components/pages/login/LoginPage';
import ProductPage from './components/pages/product/ProductPage';
import LoadingPage from './components/pages/loading/LoadingPage';
import ProductDetailPage from './components/pages/product_detail/ProductDetailPage';

import './App.css';

function Pages() {
    return (
        <Routes>
            <Route path="/giris" element={<LoginPage />} />
            <Route path="/" element={<MainPage />} />
            <Route path="/urunler/:type" element={<ProductPage />} />
            <Route path="/urunler/:type/:id" element={<ProductDetailPage />} />
        </Routes>
    );
}

function App() {
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const authenticated = useAuthentication();

    const startup = async () => {
        AxiosUtils.setDefaultSettings();
        if (authenticated) {
            AxiosUtils.setAuthHeader();
            if (location.pathname === '/giris') {
                navigate('/');
            }
        } else {
            localStorage.clear();
        }
        await CommonUtils.sleep(1000);
        setLoading(false);
    };

    useEffect(() => {
        startup();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="app">
            {loading ? (
                <LoadingPage />
            ) : (
                <>
                    <NavBar />
                    <div className="content">
                        <Pages />
                    </div>
                </>
            )}
        </div>
    );
}

export default App;
