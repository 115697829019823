import { BsX } from 'react-icons/bs';

import './ToastNotification.css';

function ToastNotification(props) {
    return (
        <div id={props.id && props.id} className="notification">
            <div className="notification_message_container">
                {props.message}
            </div>
            <BsX className="notification_close_icon" onClick={props.onClose} />
        </div>
    );
}

function ToastNotificationList(props) {
    return (
        <div className="notification_list" aria-live="assertive">
            {props.children}
        </div>
    );
}

export { ToastNotificationList, ToastNotification };
