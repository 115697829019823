import axios from 'axios';

import LocalStorage from '../constants/LocalStorage';

class UserService {
    static async getCurrentUser() {
        const headers = {
            Authorization: `Bearer ${
                localStorage[LocalStorage.USER_ACCESS_TOKEN]
            }`,
            'Content-Type': 'application/json',
        };
        const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/user/me`,
            { headers: headers }
        );
        return response;
    }
}

export default UserService;
