import { useState } from 'react';

import {
    MdOutlineArrowBackIos,
    MdOutlineArrowForwardIos,
} from 'react-icons/md';

import './Carousel.css';

function CarouselImage(props) {
    const [loading, setLoading] = useState(true);
    return (
        <img
            className={loading ? 'carousel_image hidden' : 'carousel_image'}
            style={props.aspectRatio && { aspectRatio: props.aspectRatio }}
            src={props.src}
            alt={props.alt ? props.alt : props.src}
            onLoad={() => setLoading(false)}
            onClick={props.onClick}
        />
    );
}

function CarouselSkeleton(props) {
    return (
        <div
            className="carousel loading_shine"
            style={props.aspectRatio && { aspectRatio: props.aspectRatio }}
        ></div>
    );
}

function Carousel(props) {
    const [imageIndex, setImageIndex] = useState(0);

    const prevImage = () => {
        setImageIndex((prevState) =>
            prevState - 1 < 0 ? props.data.length - 1 : prevState - 1
        );
    };

    const nextImage = () => {
        setImageIndex((prevState) =>
            prevState + 1 < props.data.length ? prevState + 1 : 0
        );
    };

    return props.loading ? (
        <CarouselSkeleton aspectRatio={props.aspectRatio} />
    ) : (
        <div
            className="carousel"
            style={props.aspectRatio && { aspectRatio: props.aspectRatio }}
        >
            <div className="carousel_layer align_left">
                <MdOutlineArrowBackIos
                    className="carousel_arrow"
                    onClick={() => prevImage()}
                />
            </div>
            <div className="carousel_layer align_right">
                <MdOutlineArrowForwardIos
                    className="carousel_arrow"
                    onClick={() => nextImage()}
                />
            </div>

            {props.data && (
                <div className="carousel_layer align_bottom">
                    <div className="carousel_dots">
                        {props.data.map((_, index) => (
                            <div
                                key={index}
                                className={
                                    index === imageIndex
                                        ? 'carousel_dot selected'
                                        : 'carousel_dot'
                                }
                            ></div>
                        ))}
                    </div>
                </div>
            )}
            {props.data && (
                <div
                    className="carousel_image_container"
                    style={{
                        width: `${props.data.length * 100}%`,
                        left: `-${imageIndex * 100}%`,
                    }}
                >
                    {props.data.map((item, index) => (
                        <CarouselImage
                            key={index}
                            src={item.src}
                            alt={item.alt}
                            onClick={item.onClick}
                            aspectRatio={props.aspectRatio}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

export default Carousel;
