class ProductConstants {
    static filteringTypes = {
        SEARCH: 'search',
        SORT: 'sort',
        FILTER: 'filter',
    };

    static productTypes = {
        COFFEE: { value: 'kahve', title: 'Kahve' },
        CHOCOLATE: { value: 'cikolata', title: 'Çikolata' },
        CACAO: { value: 'kakao', title: 'Kakao' },
        TEA: { value: 'cay', title: 'Çay' },
        EQUIPMENT: { value: 'ekipman', title: 'Ekipman' },
    };
}

export default ProductConstants;
