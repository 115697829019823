import ProductConstants from '../constants/ProductConstants';
import FileUtils from './FileUtils';

class ProductUtils {
    static getFirstImage(images) {
        return images && images.length > 0
            ? images.sort((a, b) => a['index'] - b['index'])[0]
            : null;
    }

    static async convertProductImages(images) {
        for (const i in images) {
            images[i].url = await FileUtils.convertToObjectUrl(images[i].url);
        }
        return images;
    }

    static createProductGroupData(products, navigate) {
        return products.map((item) => {
            const productType =
                ProductConstants.productTypes[
                    item['product_type'].toUpperCase()
                ].value;
            return {
                src: ProductUtils.getFirstImage(item['images'])['url'],
                name: item.name,
                description: item.description,
                price: item.price,
                onClick: () =>
                    navigate(`/urunler/${productType}/${item['stock_code']}`),
            };
        });
    }
}

export default ProductUtils;
