import './LabelGroup.css';

function Label(props) {
    const className = props.className ? `label ${props.className}` : 'label';
    return <div className={className}>{props.label}</div>;
}

function LabelGroupSkeleton() {
    return (
        <div className="label_group">
            <Label className="loading_shine group1" />
            <Label className="loading_shine group2" />
            <Label className="loading_shine group3" />
        </div>
    );
}

function LabelGroup(props) {
    const setClassName = (index) => {
        if (index === 0) {
            return 'group1';
        } else if (index === 1) {
            return 'group2';
        } else {
            return 'group3';
        }
    };

    return props.loading ? (
        <LabelGroupSkeleton />
    ) : (
        <div className="label_group">
            {props.labels &&
                props.labels.length > 0 &&
                props.labels.map((label, index) => (
                    <Label className={setClassName(index)} label={label} />
                ))}
        </div>
    );
}

export default LabelGroup;
