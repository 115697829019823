import axios from 'axios';

import ChocolateModels from '../models/ChocolateModels';
import CoffeeModels from '../models/CoffeeModels';
import CacaoModels from '../models/CacaoModels';
import TeaModels from '../models/TeaModels';

class CoffeeService {
    static async createCoffeeType(data) {
        const dataModel = new CoffeeModels.CreateCoffeeType(data);
        return await axios.post('/coffee/create_coffee_type', dataModel);
    }

    static async createCoffee(data) {
        const dataModel = new CoffeeModels.CreateCoffee(data);
        return await axios.post('/coffee/create_product/coffee', dataModel);
    }

    static async getCoffees() {
        return await axios.get('/coffee/get_coffees');
    }

    static async getCoffee(data) {
        const dataModel = new CoffeeModels.GetCoffee(data);
        return await axios.get(`/coffee/get_coffee/${dataModel.stockCode}`);
    }
}

class ChocolateService {
    static async getChocolates() {
        return await axios.get('/chocolate/get_chocolates');
    }

    static async getChocolate(data) {
        const dataModel = new ChocolateModels.GetChocolate(data);
        return await axios.get(
            `/chocolate/get_chocolate/${dataModel.stockCode}`
        );
    }
}

class CacaoService {
    static async getCacaos() {
        return await axios.get('/cacao/get_cacaos');
    }

    static async getCacao(data) {
        const dataModel = new CacaoModels.GetCacao(data);
        return await axios.get(`/cacao/get_cacao/${dataModel.stockCode}`);
    }
}

class TeaService {
    static async getTeas() {
        return await axios.get('/tea/get_teas');
    }

    static async getTea(data) {
        const dataModel = new TeaModels.GetTea(data);
        return await axios.get(`/tea/get_tea/${dataModel.stockCode}`);
    }
}

export { CoffeeService, ChocolateService, CacaoService, TeaService };
